import { gql } from "@apollo/client";

export const DELETE_NOTIFICATION = gql`
  mutation notificationsDeleteMutation($pk: Int) {
    notificationsDeleteMutation(pk: $pk) {
      notification {
        id
      }
    }
  }
`;

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation markNotificationsAsReadMutation($pk: Int) {
    markNotificationsAsReadMutation(pk: $pk) {
      count
    }
  }
`;
