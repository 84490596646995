import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FavouriteOffersActions } from "./FavouriteOffersActions";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_FAVOURITE_OFFERS,
  GET_SCHEMA_BY_KEY,
  GET_SIGNLE_TASK,
} from "@/shared/graphql";
import { useParams } from "react-router";
import { Loading } from "@/components/Loading";
import { OffersEmptyState } from "@/components/OffersEmptyState";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import {
  DatePickerWidget,
  FileWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import {
  SHARE_OFFERS_WITH_BENEFICIARY,
  UPDATE_NEGOTIATOR_TASK,
  ACTIVATE_REQUEST,
} from "@/shared/graphql/mutations";
import { graphqlErrorHandler } from "@/shared/utils";
import toast from "react-hot-toast";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { InfoWidget } from "@/components/RJSF/widgets/InfoWidget/InfoWidget";
import ShareIcon from "@mui/icons-material/Share";
import RefreshIcon from "@mui/icons-material/Refresh";
// import schema12 from "@/schemas/12-choose-suitable-property.json";
// import uiSchema12 from "@/schemas/ui-schemas/12-choose-suitable-property.json";

export const FavouriteOffers = ({ setSelectedStep, refetchTask }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({
    chooseSuitableProperty: {
      isOwnerNegotiated: "لا",
      isPropertyInspected: "لا",
      isPropertySentToBeneficiary: "لا",
      suitablePropertyFounded: "لا",
    },
    negotiationOwnerValue: { negotiationOrder: {} },
    dealCompletion: { propertyVisit: {} },
  });
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});
  const formKey = "choose_suitable_property";
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { taskId } = useParams();
  const {
    data,
    refetch: refetchFavOffers,
    loading,
    error,
  } = useQuery(GET_ALL_FAVOURITE_OFFERS, {
    variables: { taskId },
  });
  const [updateTask, { loading: mutationLoading }] = useMutation(
    UPDATE_NEGOTIATOR_TASK
  );
  const [shareOffer, { loading: shareOfferLoading }] = useMutation(
    SHARE_OFFERS_WITH_BENEFICIARY
  );
  const [activateRequest, { loading: activateLoading }] =
    useMutation(ACTIVATE_REQUEST);
  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    onCompleted: async (res) => {
      const schema = JSON.parse(
        JSON.stringify(res.schemas.data?.at(0)?.jsonSchema)
      );
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema.UISchema;
      setUiSchema(fetchedUiSchema);
      setFormSchema(fetchedFormSchema);
    },
  });
  const { loading: taskLoading } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      const placeOrderFormData =
        res?.tasks?.data?.at(0)?.formData?.negotiator?.placeOrder;
      if (placeOrderFormData) {
        setFormData((prev) => ({ ...prev, ...placeOrderFormData }));
        return;
      }
    },
  });

  const onFormChange = async (form: any) => {
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));

    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };

  if (loading) return <Loading />;
  if (error) return <></>;
  const formatAddress = (address) => {
    return `${address.street}, ${address.district} , ${address.city} `;
  };
  const favoriteOffers = data?.favoriteOffers?.data;
  const shareOffers = data?.favoriteOffers?.shareOffers;

  const isLinkExpired = data?.favoriteOffers?.isLinkExpired;
  const taskHasDeal = favoriteOffers?.some((offer) => offer.hasDeal);
  const ableToCreateDeal =
    formData.chooseSuitableProperty.isOwnerNegotiated === "نعم";

  const onSubmit = (form) => {
    const submittedData = { placeOrder: form.formData };
    let submittedStatus: string | null = null;
    if (
      form.formData.chooseSuitableProperty.suitablePropertyFounded === "نعم"
    ) {
      submittedStatus = "LOOKING_FOR_PROPERTY";
    }
    if (
      form.formData.chooseSuitableProperty.isPropertySentToBeneficiary === "نعم"
    ) {
      submittedStatus = "SEND_PROPERTY_TO_BENEFICIARY";
    }
    if (form.formData.chooseSuitableProperty.isPropertyInspected === "نعم") {
      submittedStatus = "PROPERTY_INSPECTION";
    }
    if (form.formData.chooseSuitableProperty.isOwnerNegotiated === "نعم") {
      submittedStatus = "NEGOTIATE_WITH_OWNER";
    }
    if (form.formData.chooseSuitableProperty.suitablePropertyFounded === "لا") {
      submittedStatus = "IDENTIFY_SUITABLE_PROPERTY";
    }
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: false,
          status: submittedStatus,
        },
      },
    })
      .then(() => {
        refetchTask();
        toast.success(t("Data has been saved successfully"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const handleShareOffers = () => {
    shareOffer({
      variables: {
        taskId,
      },
    })
      .then(() => {
        toast.success(t("Offers shared successfully"));
        refetchFavOffers();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const handleActivateRequest = () => {
    activateRequest({
      variables: {
        taskId,
      },
    })
      .then(() => {
        toast.success(t("Request activated successfully"));
        refetchFavOffers();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  return (
    <>
      {favoriteOffers.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                {t("Favourite offers")}
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                {isLinkExpired ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleActivateRequest}
                    disabled={activateLoading}
                    sx={{ mb: 2 }}
                  >
                    <RefreshIcon fontSize="small" sx={{ mr: 1 }} />
                    {t("Activate Request")}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleShareOffers}
                    disabled={
                      mutationLoading || shareOfferLoading || !shareOffers
                    }
                    sx={{ mb: 2 }}
                  >
                    <ShareIcon fontSize="small" sx={{ mr: 1 }} />
                    {t("Share Favorite Offers")}
                  </Button>
                )}
              </Box>
            </Box>

            <Card sx={{ mb: 2 }} variant="outlined">
              <TableContainer>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Offer Id")}</TableCell>
                      <TableCell>{t("Property type")}</TableCell>
                      <TableCell>{t("Offer type")}</TableCell>
                      <TableCell>{t("Address")}</TableCell>
                      <TableCell>{t("Price")}</TableCell>
                      <TableCell>{t("Offer Status")}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {favoriteOffers?.map((offer) => {
                      return (
                        <TableRow>
                          <TableCell>{offer?.id}</TableCell>
                          <TableCell>
                            {offer?.offer?.offerData?.establishmentType ??
                              "---"}
                          </TableCell>
                          <TableCell>
                            {offer?.offer?.offerData?.offerType?.map(
                              (item, index) => (
                                <div key={index}>{item ?? "---"}</div>
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {formatAddress(offer?.offer?.offerData?.address) ??
                              "---"}
                          </TableCell>
                          <TableCell>
                            {offer?.offer?.offerData?.realEstatePrice ?? "---"}
                          </TableCell>

                          <TableCell>
                            <Chip label={t(offer?.offer?.status)} />
                          </TableCell>
                          <TableCell>
                            {offer?.reserved && (
                              <Chip
                                color="success"
                                size="small"
                                icon={<CheckCircleOutlineOutlinedIcon />}
                                label={t("beneficiary Choice")}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <FavouriteOffersActions
                              taskHasDeal={taskHasDeal}
                              refetchTask={refetchTask}
                              refetchFavOffers={refetchFavOffers}
                              favoriteOfferId={offer.id}
                              hasDeal={offer?.hasDeal}
                              setSelectedStep={setSelectedStep}
                              ableToCreateDeal={ableToCreateDeal}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Form
              schema={formSchema || {}}
              uiSchema={uiSchema || {}}
              validator={validator}
              onChange={onFormChange}
              formData={formData}
              noHtml5Validate
              showErrorList={false}
              transformErrors={(errors) => {
                const modfiedErrors = errors?.map((err) => {
                  if (
                    err.name === "required" ||
                    err.name === "minItems" ||
                    err.name === "type"
                  ) {
                    return { ...err, message: "حقل مطلوب" };
                  }
                  if (err.name === "enum") {
                    return {
                      ...err,
                      message: "يرجى الإختيار من القيم الموجودة",
                    };
                  }
                  if (err.name === "if") {
                    return {
                      ...err,
                      message: "",
                    };
                  }
                  return err;
                });
                return modfiedErrors;
              }}
              widgets={{
                FileWidget,
                YesNoWidget,
                SelectWidget,
                TextWidget,
                InfoWidget,
                DateWidget: DatePickerWidget,
              }}
              templates={{ ObjectFieldTemplate }}
              onSubmit={onSubmit}
            >
              {/* <Button
          onClick={saveAsDraft}
          variant="contained"
          sx={{ mt: 2, mr: 1 }}
          disabled={mutationLoading}
        >
          {t("save and complete later")}
        </Button> */}
              <Button
                type="submit"
                variant="contained"
                sx={{ my: 2 }}
                disabled={taskLoading || schemaLoading || mutationLoading}
              >
                {t("save")}
              </Button>
            </Form>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: "20px", py: 2 }}>
              {t("Favourite offers")}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              width: "100%",
              height: "100%",
              p: 7,
            }}
          >
            <OffersEmptyState />
          </Box>
        </Box>
      )}
    </>
  );
};
