import { Outlet, useRoutes } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { LandingPage } from "@/modules/LandingPage";
import { useAuth } from "react-oidc-context";
import {
  Beneficiaries,
  CustomersService,
  Negotiators,
  OrderTasks,
  Orders,
} from "@/modules/app/project-manager";
import {
  Tasks as CSTasks,
  Task as CSTask,
} from "@/modules/app/customer-service";
import { FormsPlayground } from "@/modules/app/FormsPlayground";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import {
  Tasks as NegotiatorTasks,
  Task as NegotiatorTask,
} from "@/modules/app/negotiator";
import { UnAuthorized } from "./UnAuthorized";
import { NotFound } from "./NotFound";
import {
  isBeneficiary,
  isCustomerService,
  isNegotiator,
  isProjectManager,
  rolesMap,
} from "./utils/mappers";
import { PrivacyPolicy } from "@/modules/PrivacyPolicy";
import { CustomerServiceBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/CustomerServiceBeneficiaries";
import { NegotiatorBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/NegotiatorBeneficiaries";
import { UnassignedBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/UnassignedBeneficiaries";
import { PendingReviewBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/PendingReviewBeneficiaries";
import { AddingOffer } from "@/modules/app/negotiator/Tasks/Task/steps/Offers/AddingOffer";
import RejectedTasks from "@/modules/app/project-manager/Beneficiaries/RejectedTasks";
import { BeneficiaryLayout } from "@/components/Layout/BeneficiaryLayout";
import Beneficiary from "@/modules/app/beneficiary/Beneficiary";
import { RequestNewOffers } from "@/modules/app/beneficiary/BeneficiaryOffers/RequestOffers/RequestNewOffer";
import OfferDetails from "@/modules/app/beneficiary/BeneficiaryOffers/OfferDetails";
import ConfirmOffer from "@/modules/app/beneficiary/BeneficiaryOffers/ConfirmOffer";
import OfferMap from "@/modules/app/beneficiary/BeneficiaryOffers/OfferMap";
import OfferSuccess from "@/modules/app/beneficiary/BeneficiaryOffers/OfferSuccess";

export const Routing = () => {
  const { isAuthenticated } = useAuth();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const isPM = isProjectManager(userInfo);
  const isCS = isCustomerService(userInfo);
  const isNegot = isNegotiator(userInfo);
  const isBenef = isBeneficiary(userInfo);

  const firstURL = rolesMap[userInfo?.role?.key || ""]?.initialUrl;

  const routes = useRoutes([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "app",
      element: isAuthenticated ? <Outlet /> : <Navigate to="/" />,
      children: [
        {
          path: "beneficiary",
          element: isBenef ? (
            <BeneficiaryLayout />
          ) : (
            <UnAuthorized role={userInfo?.role} />
          ),
          children: [
            {
              path: "",
              element: <Beneficiary />,
            },
            {
              path: "request-new-offers",
              element: <RequestNewOffers />,
            },
            {
              path: "offer-details",
              element: <OfferDetails />,
            },
            {
              path: "confirm-offer",
              element: <ConfirmOffer />,
            },
            {
              path: "offer-map",
              element: <OfferMap />,
            },
            {
              path: "offer-success",
              element: <OfferSuccess />,
            },
          ],
        },
        {
          path: "*",
          element: isAuthenticated ? <Layout /> : <Navigate to="/" />,
          children: [
            {
              path: "",
              element: <Navigate to={firstURL} replace />,
            },
            { path: "forms", element: <FormsPlayground /> },
            {
              path: "project-manager",
              element: isPM ? (
                <Outlet />
              ) : (
                <UnAuthorized role={userInfo?.role} />
              ),
              children: [
                {
                  path: "dashboard",
                  element: <div>Dashboard</div>,
                },
                {
                  path: "beneficiaries",
                  element: <Beneficiaries />,
                  children: [
                    { path: "", element: <Navigate to="unassigned" replace /> },
                    {
                      path: "unassigned",
                      element: <UnassignedBeneficiaries />,
                    },
                    {
                      path: "customer-service",
                      element: <CustomerServiceBeneficiaries />,
                    },
                    {
                      path: "negotiator",
                      element: <NegotiatorBeneficiaries />,
                    },
                    {
                      path: "pending-review",
                      element: <PendingReviewBeneficiaries />,
                    },
                    {
                      path: "rejected",
                      element: <RejectedTasks />,
                    },
                  ],
                },
                { path: "orders", element: <Orders /> },
                {
                  path: "orders/:orderId",
                  element: <OrderTasks />,
                },
                {
                  path: "orders/:orderId/customer-task/:taskId",
                  element: <CSTask />,
                },
                {
                  path: "orders/:orderId/negotiator-task/:taskId",
                  element: <NegotiatorTask />,
                },
                {
                  path: "customer-service",
                  element: <CustomersService />,
                },
                {
                  path: "negotiators",
                  element: <Negotiators />,
                },
              ],
            },
            {
              path: "customer-service",
              element: isCS ? (
                <Outlet />
              ) : (
                <UnAuthorized role={userInfo?.role} />
              ),
              children: [
                {
                  path: "tasks",
                  element: <CSTasks />,
                },
                {
                  path: "tasks/:taskId",
                  element: <CSTask />,
                },
              ],
            },
            {
              path: "negotiator",
              element: isNegot ? (
                <Outlet />
              ) : (
                <UnAuthorized role={userInfo?.role} />
              ),
              children: [
                {
                  path: "tasks",
                  element: <NegotiatorTasks />,
                },
                {
                  path: "tasks/:taskId",
                  element: <NegotiatorTask />,
                },
                {
                  path: "tasks/:taskId/add-offer",
                  element: <AddingOffer formMode="create" />,
                },
                {
                  path: "tasks/:taskId/edit-offer/:offerId",
                  element: <AddingOffer formMode="edit" />,
                },
              ],
            },

            {
              path: "*",
              element: <NotFound />,
            },
          ],
        },
      ],
    },
  ]);
  return <>{routes}</>;
};
