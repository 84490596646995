import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppCollapse } from "@/components/AppCollapse";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import KitchenOutlinedIcon from "@mui/icons-material/KitchenOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import GarageOutlinedIcon from "@mui/icons-material/GarageOutlined";

const FieldRow = ({ label, value }) => (
  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
    <Typography color="text.secondary">{label}</Typography>
    <Typography fontWeight="500">{value || "---"}</Typography>
  </Box>
);

const BorderTabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`border-tabpanel-${index}`}
      aria-labelledby={`border-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const LandBorderTabs = ({ borders }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const borderDirections = ["east", "west", "north", "south"];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (!borders) return null;

  return (
    <Box sx={{ width: "100%", mt: 3 }}>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        {t("Borders")}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="border tabs"
        >
          {borderDirections.map((direction, index) => (
            <Tab
              key={direction}
              label={t(direction)}
              id={`border-tab-${index}`}
              aria-controls={`border-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Box>

      {borderDirections.map((direction, index) => {
        const borderData = borders[direction];
        return (
          <BorderTabPanel key={direction} value={selectedTab} index={index}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <FieldRow
                label={t("Border Length")}
                value={`${borderData?.borderLengthNumeric || "---"} ${t(
                  "meters"
                )}`}
              />
              <FieldRow
                label={t("Border Type")}
                value={
                  borderData?.borderType ? t(borderData.borderType) : "---"
                }
              />
              <FieldRow
                label={t("Is Winding")}
                value={borderData?.isWinding ? t(borderData.isWinding) : "---"}
              />
              <FieldRow
                label={t("Border Description")}
                value={borderData?.borderDescription || "---"}
              />
            </Box>
          </BorderTabPanel>
        );
      })}
    </Box>
  );
};

const PropertyFields = ({ selectedOffer }) => {
  const { t } = useTranslation();

  if (!selectedOffer) {
    return null;
  }

  const { realEstateDetails } = selectedOffer.data;

  const renderLandFields = (land) => {
    if (!land) return null;
    return (
      <>
        <AppCollapse title={t("Land Details")}>
          <FieldRow
            label={t("Land Area")}
            value={`${land.landArea} ${t("sqmeters")}`}
          />
          <FieldRow label={t("Land Nature")} value={t(land.secondLandNature)} />
          <FieldRow
            label={t("Number of Views")}
            value={land.numberOfViews?.count}
          />
          <FieldRow label={t("Land Level")} value={t(land.landLevel)} />
          <FieldRow label={t("Is Land Fenced")} value={t(land.isLandFenced)} />
          {land.isLandFenced === "yes" && (
            <FieldRow label={t("Fence Type")} value={t(land.fenceType)} />
          )}
          <FieldRow label={t("Is Road Paved")} value={t(land.isRoadPaved)} />
        </AppCollapse>

        <AppCollapse title={t("Land Building System")}>
          <FieldRow
            label={t("Main Use Of Plan")}
            value={t(land.buildingSystemE?.mainUseOfPlan)}
          />
          <FieldRow
            label={t("Sub Use Of Plan")}
            value={t(land.buildingSystemE?.subUseOfPlan)}
          />
          <FieldRow
            label={t("Building Percentage")}
            value={`${land.buildingSystemE?.buildingPercentageOfLand}%`}
          />
          <FieldRow
            label={t("Land Building System")}
            value={`${land.buildingSystemE?.landBuildingSystem}%`}
          />
        </AppCollapse>
      </>
    );
  };

  const renderDepartmentFields = (department) => {
    if (!department) return null;
    return (
      <>
        <AppCollapse title={t("Unit Details")}>
          <FieldRow
            label={t("Building Number")}
            value={department?.buildingNumber}
          />
          <FieldRow label={t("Unit Number")} value={department.unitNumber} />
          <FieldRow label={t("Floor Number")} value={department.floorOfUnit} />
          <FieldRow
            label={t("Area")}
            value={`${department.theArea} ${t("sqmeters")}`}
          />
          <FieldRow
            label={t("Unit Land Area")}
            value={`${department.unitLandArea} ${t("sqmeters")}`}
          />
          <FieldRow
            label={t("Building Age")}
            value={t(department.buildingAge)}
          />
          <FieldRow
            label={t("Building Date")}
            value={department.buildingDate}
          />
          <FieldRow
            label={t("Building State")}
            value={t(department.buildingState)}
          />
          <FieldRow
            label={t("Number of Floors")}
            value={department.numberOfFloors}
          />
          {department.buildingWarranties?.length > 0 && (
            <FieldRow
              label={t("Building Warranties")}
              value={department.buildingWarranties
                .map((warranty) => t(warranty))
                .join(", ")}
            />
          )}
          {department.propertyAdvantages?.length > 0 && (
            <FieldRow
              label={t("Advantages")}
              value={department.propertyAdvantages
                .map((advantage) => t(advantage))
                .join(", ")}
            />
          )}
          {department.landFacts && (
            <Box>
              <FieldRow
                label={t("Land Area")}
                value={`${department.landFacts.landArea} ${t("sqmeters")}`}
              />
              <FieldRow
                label={t("Land Nature")}
                value={t(department.landFacts.landNature)}
              />
            </Box>
          )}
          {department.numberOfViews?.count > 0 && (
            <Box>
              <FieldRow
                label={t("Number of Views")}
                value={department.numberOfViews.count}
              />
              {department.numberOfViews.items?.map((view, index) => (
                <Box key={index}>
                  <FieldRow label={t("Direction")} value={t(view.direction)} />
                  <FieldRow
                    label={t("Street Width")}
                    value={`${view.mainFacadesWidth} ${t("meters")}`}
                  />
                </Box>
              ))}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            {department.isThereHall === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ChairOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {department.HallCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Hall")}
                  </Typography>
                </Box>
              </Box>
            )}
            {department.isThereBedroom === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BedOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {department.BedroomCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Bedrooms")}
                  </Typography>
                </Box>
              </Box>
            )}
            {department.isThereKitchen === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <KitchenOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {department.KitchenCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Kitchens")}
                  </Typography>
                </Box>
              </Box>
            )}
            {department.isThereBathroom === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BathtubOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {department.BathroomCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Bathrooms")}
                  </Typography>
                </Box>
              </Box>
            )}
            {department.isThereMeqllat === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <GarageOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {department.MeqllatCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Meqllat")}
                  </Typography>
                </Box>
              </Box>
            )}
            {department.isThereLivingroom === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ChairOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {department.LivingroomCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Living Room")}
                  </Typography>
                </Box>
              </Box>
            )}
            {department.isThereFoodcourt === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <KitchenOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {department.FoodcourtCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Food Court")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </AppCollapse>
      </>
    );
  };

  const renderShopFields = (shop) => {
    if (!shop) return null;
    return (
      <>
        <AppCollapse title={t("Shop Details")}>
          <FieldRow
            label={t("Area")}
            value={`${shop.theArea} ${t("sqmeters")}`}
          />
          <FieldRow
            label={t("Unit Land Area")}
            value={`${shop.unitLandArea} ${t("sqmeters")}`}
          />
          <FieldRow
            label={t("Number of Openings")}
            value={shop.numberOfOpenings}
          />
          {shop.numberOfViews?.count > 0 && (
            <>
              <FieldRow
                label={t("Number of Views")}
                value={shop.numberOfViews.count}
              />
              {shop.numberOfViews.items?.map((view, index) => (
                <Box key={index}>
                  <FieldRow label={t("Direction")} value={t(view.direction)} />
                  <FieldRow
                    label={t("Street Width")}
                    value={`${view.mainFacadesWidth} ${t("meters")}`}
                  />
                </Box>
              ))}
            </>
          )}
        </AppCollapse>
      </>
    );
  };

  const renderBuildingFields = (building) => {
    if (!building) return null;
    return (
      <>
        <AppCollapse title={t("Unit Details")}>
          <FieldRow
            label={t("Building Number")}
            value={building?.buildingNumber}
          />
          <FieldRow label={t("Building Date")} value={building?.buildingDate} />
          <FieldRow label={t("Building Age")} value={building?.buildingAge} />
          <FieldRow
            label={t("Building State")}
            value={t(building?.buildingState)}
          />
          <FieldRow
            label={t("Finishing Type")}
            value={t(building?.finishingType)}
          />
          <FieldRow
            label={t("Number of Floors")}
            value={building?.numberOfFloors}
          />
          {building?.buildingWarranties?.length > 0 && (
            <FieldRow
              label={t("Building Warranties")}
              value={building?.buildingWarranties
                .map((warranty) => t(warranty))
                .join(", ")}
            />
          )}
          {building?.propertyAdvantages?.length > 0 && (
            <FieldRow
              label={t("Advantages")}
              value={building?.propertyAdvantages
                .map((advantage) => t(advantage))
                .join(", ")}
            />
          )}

          {building?.numberOfViews?.count > 0 && (
            <Box>
              <FieldRow
                label={t("Number of Views")}
                value={building?.numberOfViews.count}
              />
              {building?.numberOfViews.items?.map((view, index) => (
                <Box key={index}>
                  <FieldRow label={t("Direction")} value={t(view.direction)} />
                  <FieldRow
                    label={t("Street Width")}
                    value={`${view.mainFacadesWidth} ${t("meters")}`}
                  />
                </Box>
              ))}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            {building?.isThereHall === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ChairOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {building?.HallCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Hall")}
                  </Typography>
                </Box>
              </Box>
            )}
            {building?.isThereBedroom === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BedOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {building?.BedroomCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Bedrooms")}
                  </Typography>
                </Box>
              </Box>
            )}
            {building?.isThereKitchen === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <KitchenOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {building?.KitchenCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Kitchens")}
                  </Typography>
                </Box>
              </Box>
            )}
            {building?.isThereBathroom === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BathtubOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {building?.BathroomCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Bathrooms")}
                  </Typography>
                </Box>
              </Box>
            )}
            {building?.isThereMeqllat === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <GarageOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {building?.MeqllatCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Meqllat")}
                  </Typography>
                </Box>
              </Box>
            )}
            {building?.isThereLivingroom === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ChairOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {building?.LivingroomCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Living Room")}
                  </Typography>
                </Box>
              </Box>
            )}
            {building?.isThereFoodcourt === "yes" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <KitchenOutlinedIcon />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1" fontWeight="700">
                    {building?.FoodcourtCount}
                  </Typography>
                  <Typography variant="body1" fontWeight="700">
                    {t("Food Court")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </AppCollapse>
        <AppCollapse title={t("Land Details")}>
          {building?.landFacts && (
            <Box>
              <FieldRow
                label={t("Land Area")}
                value={`${building?.landFacts.landArea} ${t("sqmeters")}`}
              />
              <FieldRow
                label={t("Land Nature")}
                value={t(building?.landFacts.landNature)}
              />
            </Box>
          )}
        </AppCollapse>
      </>
    );
  };

  const renderFieldsByType = () => {
    switch (selectedOffer?.offerData?.establishmentType) {
      case "أرض":
        return renderLandFields(realEstateDetails?.land);
      case "شقة":
      case "دور":
      case "ملحق":
      case "غرفة":
      case "فيلا دوبلكس":
      case "مكتب":
        return renderDepartmentFields(realEstateDetails?.Department);
      case "محل":
      case "معرض":
      case "مستودع":
      case "ورشة":
        return renderShopFields(realEstateDetails?.shop);
      case "فيلا":
      case "عمارة":
      case "برج":
      case "مزرعة":
      case "استراحة":
        return renderBuildingFields(realEstateDetails?.buildingFacts);
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AppCollapse title={t("Location")}>
        <FieldRow
          label={t("region")}
          value={t(selectedOffer?.data?.location?.areaName || "")}
        />
        <FieldRow
          label={t("zone")}
          value={`${selectedOffer?.data?.location?.mainDivision || "---"}`}
        />
        <FieldRow
          label={t("parcel_number")}
          value={t(selectedOffer?.data?.location?.plotNumber || "")}
        />
        <FieldRow
          label={t("_plan_number")}
          value={`${selectedOffer?.data?.location?.planNumber || "---"} `}
        />
      </AppCollapse>
      <AppCollapse title={t("real Estate Details")}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {selectedOffer?.data?.realEstateDetails?.isThereIsElectricity && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <LightbulbOutlinedIcon />
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography variant="body1" fontWeight="700">
                  {
                    selectedOffer?.data?.realEstateDetails
                      ?.electricityMeterCount
                  }
                </Typography>
                <Typography variant="body1" fontWeight="700">
                  {t("Electricity Meter")}
                </Typography>
              </Box>
            </Box>
          )}

          {selectedOffer?.data?.realEstateDetails?.areThereWaterServices && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <WaterDropOutlinedIcon />
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography variant="body1" fontWeight="700">
                  {selectedOffer?.data?.realEstateDetails?.waterMeterCount}
                </Typography>
                <Typography variant="body1" fontWeight="700">
                  {t("Water Meter")}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        {selectedOffer?.offerData?.establishmentType === "أرض" && (
          <LandBorderTabs
            borders={selectedOffer?.data?.realEstateDetails?.bordersAndLengths}
          />
        )}
      </AppCollapse>

      {renderFieldsByType()}

      <AppCollapse title={t("Financial Details")}>
        <FieldRow
          label={t("Real Estate Price")}
          value={`${selectedOffer?.data?.financialData?.realEstatePrice} ${selectedOffer?.data?.financialData?.realEstatePriceUnit}`}
        />

        <FieldRow
          label={t("Real Estate Rented")}
          value={t(
            `${selectedOffer?.data?.financialData?.realEstateRevenue?.isPropertyRented}`
          )}
        />
        {selectedOffer?.data?.financialData?.realEstateRevenue
          ?.isThereRevenue === "yes" && (
          <FieldRow
            label={t("Revenue")}
            value={
              selectedOffer?.data?.financialData?.realEstateRevenue
                ?.realEstateRevenueUnit
            }
          />
        )}
      </AppCollapse>

      {selectedOffer?.data?.infrastructureServices &&
        Object.keys(selectedOffer?.data?.infrastructureServices).length > 0 && (
          <AppCollapse title={t("Area infrastructure Services")}>
            <FieldRow
              label={t("electricity")}
              value={t(
                `${selectedOffer?.data?.infrastructureServices?.electricity}`
              )}
            />
            <FieldRow
              label={t("internet")}
              value={t(
                `${selectedOffer?.data?.infrastructureServices?.internet}`
              )}
            />
            <FieldRow
              label={t("sewage")}
              value={t(
                `${selectedOffer?.data?.infrastructureServices?.sewage}`
              )}
            />
            <FieldRow
              label={t("water")}
              value={t(`${selectedOffer?.data?.infrastructureServices?.water}`)}
            />
            <FieldRow
              label={t("lighting")}
              value={t(
                `${selectedOffer?.data?.infrastructureServices?.lighting}`
              )}
            />
            <FieldRow
              label={t("telephone")}
              value={t(
                `${selectedOffer?.data?.infrastructureServices?.telephone}`
              )}
            />
            <FieldRow
              label={t("pavement")}
              value={t(
                `${selectedOffer?.data?.infrastructureServices?.pavement}`
              )}
            />
          </AppCollapse>
        )}
    </Box>
  );
};

export default PropertyFields;
