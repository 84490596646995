import { gql } from "@apollo/client";

export const GET_ALL_DEALS = gql`
  query GetDeals($taskId: Int!) {
    deals(taskId: $taskId) {
      data {
        id
        task {
          id
          formData
        }

        status
        formData
      }
      count
    }
  }
`;
