import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useMutation } from "@apollo/client";
import {
  CREATE_FAVORITE_OFFER,
  DELETE_OFFER_FROM_FAVOURITES,
} from "@/shared/graphql/mutations";
import toast from "react-hot-toast";
import { graphqlErrorHandler } from "@/shared/utils";

export const OfferDetails = ({ offer, refetchOffers }) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const { taskId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [createFavoriteOffer, { loading: addFavoriteMutationLoading }] =
    useMutation(CREATE_FAVORITE_OFFER);
  const [deleteOffer, { loading: removeMutationLoading }] = useMutation(
    DELETE_OFFER_FROM_FAVOURITES
  );

  const expandClick = () => {
    setExpand((old) => !old);
  };

  if (!offer) return;

  const formatAddress = (address) => {
    return `${address.street}, ${address.district} , ${address.city} `;
  };

  const addToFavorites = (offerId) => {
    createFavoriteOffer({
      variables: {
        offerInput: { taskId, offerId },
      },
    })
      .then(() => {
        refetchOffers();
        toast.success(t("Offer added to favorites"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const onDelete = (offerId) => {
    deleteOffer({
      variables: {
        favoriteOfferId: Number(offerId),
      },
    })
      .then(() => {
        toast.success(t("Offer Deleted Successfully"));
        refetchOffers();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  return (
    <Card raised sx={{ boxShadow: "0px 2px 4px -2px #DEDEDE9E", mb: 3 }}>
      <Box
        component={"button"}
        sx={{
          background: "transparent",
          border: "none",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2.4,
          py: 2.6,
          borderRadius: 2,
          cursor: "pointer",
        }}
        onClick={expandClick}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography sx={{ fontWeight: 600, mr: 1 }} variant="h5">
              {offer.offerData?.realEstatePrice} ر.س
            </Typography>
            <Box
              sx={{ background: "#F2DBD9", py: 0.4, px: 1, borderRadius: 5 }}
            >
              <Typography
                fontSize="small"
                sx={{ color: "#BB493E", fontWeight: "bold" }}
              >
                {`${t(offer.status) ?? "---"}`}
              </Typography>
            </Box>
          </Box>
          <Box
            component={Button}
            sx={{ display: "flex", alignItems: "center" }}
            onClick={handleMenuOpen}
          >
            <Typography sx={{ fontWeight: 600, mr: 1 }}>
              {offer.negotiators?.count === 1
                ? `${offer?.negotiators?.count} ${t("Negotiator")}`
                : `${offer?.negotiators?.count} ${t("Negotiators")}`}
            </Typography>

            <ExpandMore fontSize={"medium"} htmlColor="#2C2C2C" />

            <Collapse in={expand} timeout="auto" unmountOnExit>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
              >
                {offer.negotiators?.data?.length > 0 ? (
                  offer.negotiators.data.map((negotiator, index) => (
                    <MenuItem key={index} onClick={handleMenuClose}>
                      {negotiator.firstName} {negotiator.lastName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>لا يوجد مفاوضين</MenuItem>
                )}
              </Menu>
            </Collapse>
          </Box>
        </Box>
      </Box>
      <Box component="div" sx={{ px: 2.4, pb: 2.8, pt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Box>
            <Box display="flex" sx={{ mb: 1 }}>
              <Typography
                sx={{
                  borderRight: "1px solid #2C2C2C",
                  px: 0.8,
                  height: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {offer?.id ?? "---"}
              </Typography>
              <Typography
                sx={{
                  borderRight: "1px solid #2C2C2C",
                  px: 0.8,
                  height: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {offer?.offerData?.establishmentType ?? "---"}
              </Typography>

              <Typography
                sx={{
                  px: 0.8,
                  height: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {offer?.offerData?.offerType?.map((item, index) => (
                  <div key={index}>{item ?? "---"}</div>
                ))}
              </Typography>
            </Box>
            <Box display="flex">
              <FmdGoodOutlinedIcon sx={{ color: "#8C8C8C" }} />
              <Typography sx={{ color: "#8C8C8C" }}>
                {formatAddress(offer.offerData.address) ?? "---"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {offer?.favoriteOfferId ? (
              <IconButton
                onClick={() => onDelete(offer?.favoriteOfferId)}
                disabled={addFavoriteMutationLoading}
              >
                <StarRoundedIcon
                  sx={{
                    color: "#FFD700",
                  }}
                  fill="currentColor"
                  fontSize="large"
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => addToFavorites(offer?.id)}
                disabled={removeMutationLoading}
              >
                <StarBorderRoundedIcon
                  sx={{
                    color: "#8C8C8C",
                  }}
                  fontSize="large"
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
