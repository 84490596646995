import { StatusChip } from "@/components/StatusChip";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Box,
} from "@mui/material";
import { t } from "i18next";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";

export const RejectedBeneficiariesList: FC<any> = ({
  tasks,
  selectedTasks,
  setSelectedTasks,
}) => {
  let isAllChecked = true;
  tasks?.forEach((tsk) => {
    if (selectedTasks.includes(tsk?.beneficiary?.id)) {
      isAllChecked = isAllChecked && true;
    } else {
      isAllChecked = isAllChecked && false;
    }
  });
  const handleCheckboxChange = (isChecked: boolean, taskId: number) => {
    if (!isChecked) {
      setSelectedTasks((prev: number[]) => {
        return [...prev, taskId];
      });
    } else {
      setSelectedTasks((prev: number[]) => {
        const updated = prev.filter((el) => el !== taskId);
        return updated;
      });
    }
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedTasks((prev) => {
        const updated = [...prev];
        tasks.forEach((tsk) => {
          if (!updated.includes(tsk?.beneficiary?.id)) {
            updated.push(tsk?.beneficiary?.id);
          }
        });

        return updated;
      });
    } else {
      setSelectedTasks((prev) => {
        let updated = [...prev];
        tasks.forEach((tsk) => {
          updated = updated.filter((id) => tsk?.beneficiary?.id !== id);
        });
        return updated;
      });
    }
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                sx={{ padding: 0 }}
                checked={isAllChecked}
                onChange={handleSelectAll}
                size="small"
              />
            </TableCell>

            <TableCell>{t("Task Number")}</TableCell>
            <TableCell>{t("External ID")}</TableCell>
            <TableCell>{t("Benificiary name")}</TableCell>
            <TableCell>{t("Employee name")}</TableCell>
            <TableCell>{t("Status")}</TableCell>
            <TableCell>{t("RejectionDetect")}</TableCell>
            <TableCell>{t("Reject Reason")}</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks?.map((task) => {
            const customerRejected =
              task?.formData?.customer_service?.beneficiaryData?.contact
                ?.willingnessToContinueProgram ===
              "لا يرغب في استكمال البرنامج";

            const assignedTo = task?.assignedStatus;
            const isChecked = selectedTasks.includes(task?.beneficiary?.id);

            let navigateTo = "";
            if (
              assignedTo.key === 2 ||
              assignedTo.key === 5 ||
              (assignedTo.key === 1 && task?.status?.key === 3)
            ) {
              navigateTo = `../../orders/${task.order.id}/customer-task/${task.id}`;
            } else if (
              assignedTo.key === 3 ||
              (assignedTo.key === 1 && task?.status?.key === 5)
            ) {
              navigateTo = `../../orders/${task.order.id}/negotiator-task/${task.id}`;
            }

            return (
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={() =>
                      handleCheckboxChange(isChecked, task?.beneficiary?.id)
                    }
                    sx={{ padding: 0 }}
                    checked={isChecked}
                    size="small"
                  />
                </TableCell>

                <TableCell>
                  <Box
                    component={NavLink}
                    to={navigateTo}
                    sx={{
                      color: "rgb(0, 107, 214)",
                    }}
                  >
                    {task?.id}
                  </Box>
                </TableCell>
                <TableCell>{task?.beneficiary?.externalId}</TableCell>
                <TableCell>
                  {
                    task?.formData?.customer_service?.beneficiaryData
                      ?.personalData?.name
                  }
                </TableCell>

                <TableCell>
                  {task?.negotiator
                    ? `${task?.negotiator?.firstName} ${task?.negotiator?.lastName}`
                    : `${task?.customerService?.firstName} ${task?.customerService?.lastName}`}
                </TableCell>

                <TableCell>
                  <StatusChip status={task.status} />
                </TableCell>
                <TableCell>
                  {customerRejected
                    ? `${t("Customer Service")}`
                    : `${t("Negotiator")}`}
                </TableCell>

                <TableCell>
                  {`${
                    task?.formData?.customer_service?.beneficiaryData?.contact
                      ?.reason
                  } ${
                    task?.formData?.customer_service?.beneficiaryData?.contact
                      ?.comment ?? ""
                  }`}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
