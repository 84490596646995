import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
  query getOrders($limit: BoundedInt!, $offset: BoundedInt!, $orderBy: String) {
    orders(pageInfo: { limit: $limit, offset: $offset, orderBy: $orderBy }) {
      data {
        id
        created
        modified
        startDate
        endDate
        tasksCount
        rejectedTasksCount
        pendingReviewTasksCount
        assignedToCustomerServicesTaskCount
        assignedToNegotiatorTaskCount
      }
      count
    }
  }
`;

export const GET_TASKS = gql`
  query GetTasks(
    $role: UserRoleChoices!
    $limit: BoundedInt!
    $offset: BoundedInt!
    $filters: [DjangoFilterInput]!
    $orderBy: String!
  ) {
    tasks(
      pageInfo: { limit: $limit, offset: $offset, orderBy: $orderBy }
      filters: $filters
      role: $role
    ) {
      data {
        id
        created
        modified
        status
        assignedStatus
        formData
        customerService {
          firstName
          lastName
        }
        negotiator {
          firstName
          lastName
        }
        beneficiary {
          externalId
        }
        order {
          id
          startDate
          endDate
        }
      }
      count
    }
  }
`;

export const GET_REJECTED_TASKS = gql`
  query GetTasks($limit: BoundedInt!, $offset: BoundedInt!, $orderBy: String!) {
    rejectedTasks(
      pageInfo: { limit: $limit, offset: $offset, orderBy: $orderBy }
    ) {
      data {
        id
        created
        modified
        status
        assignedStatus
        formData
        customerService {
          firstName
          lastName
        }
        negotiator {
          firstName
          lastName
        }
        beneficiary {
          externalId
          id
        }
        order {
          id
          startDate
          endDate
        }
      }
      count
    }
  }
`;
export const GET_SIGNLE_TASK = gql`
  query GetSingleTask($pk: Int!, $role: UserRoleChoices!) {
    tasks(pk: $pk, role: $role, pageInfo: {}) {
      data {
        id
        created
        modified
        status
        formData
        callLogs
      }
      count
    }
  }
`;
