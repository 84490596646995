// import { Grid } from "@mui/material";
// import { OfferDetails } from "./OfferDetails";
// import { OffersTable } from "./OffersTable";
// import { OffersMap } from "./OffersMap";

import { OffersEmptyState } from "@/components/OffersEmptyState";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add"; // Import Material-UI Add icon
import { GET_ALL_OFFERS } from "@/shared/graphql";
import { useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import { OffersTable } from "./OffersTable";
import { OfferDetails } from "./OfferDetails";
// import { OffersMap } from "./OffersMap";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const Offers = () => {
  const { t } = useTranslation();
  const [selectedOffer, setSelectedOffer] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 8;
  const navigate = useNavigate();
  const { taskId } = useParams();
  const handleClick = () => {
    if (taskId) {
      navigate(`/app/negotiator/tasks/${taskId}/add-offer`);
    } else {
      console.error("Task ID is missing from the URL");
    }
  };

  const handleRowClick = (offer) => {
    setSelectedOffer(offer);
  };
  const {
    data,
    refetch: refetchOffers,
    loading,
    error,
  } = useQuery(GET_ALL_OFFERS, {
    variables: {
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      taskId: taskId,
    },
    onCompleted: (data) => {
      const offers = data?.offers?.data;
      setSelectedOffer(offers?.at(0)?.id);
    },
  });

  const offers = data?.offers?.data;
  const offersCount = data?.offers?.count;
  // const offersBoundries = data?.offers?.boundaries;
  // const offersGeometries = data?.offers?.geometries;
  const numberOfPages = Math.ceil(offersCount / pageLimit);

  if (loading) return <Loading />;
  if (error) return <></>;

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  return (
    <Box sx={{ py: 3, textAlign: "center" }}>
      {offersCount > 0 ? (
        <>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              lg={8}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                  {t("Offers")}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ px: 1.5 }}
                  onClick={handleClick}
                >
                  {t("Add Offer")}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              <OfferDetails
                offer={offers.find((offer) => offer.id === selectedOffer)}
                refetchOffers={refetchOffers}
              />
              <OffersTable
                offers={offers || []}
                refetchOffers={refetchOffers}
                onRowClick={handleRowClick}
                selectedOffer={selectedOffer}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              {/* <OffersMap
                offersBoundries={offersBoundries ?? []}
                offersGeometries={offersGeometries ?? []}
                setSelectedOffer={setSelectedOffer}
                selectedOffer={selectedOffer}
              /> */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Pagination
                count={numberOfPages || 1}
                page={Number(searchParams.get("page") || 1)}
                onChange={handlePagination}
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <OffersEmptyState />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ py: 1, px: 8 }}
            onClick={handleClick}
          >
            {t("Add Offer")}
          </Button>
        </>
      )}
    </Box>
  );
};
