import { gql } from "@apollo/client";

export const REQUEST_NEW_OFFERS = gql`
  mutation RequestOffers($requestInput: RequestInputType!) {
    createRequestMutation(requestInput: $requestInput) {
      request {
        id
      }
    }
  }
`;
export const CHOOSE_OFFER = gql`
  mutation choseDealFavoriteOffer(
    $favoriteOfferInput: ReplaceDealFavoriteOfferInputType!
  ) {
    choseDealFavoriteOffer(favoriteOfferInput: $favoriteOfferInput) {
      favoriteOffer {
        id
      }
    }
  }
`;

export const CREATE_ACTIVATION_NOTIFICATION = gql`
  mutation CreateActivationNotification($taskId: Int!) {
    createActivationNotification(taskId: $taskId) {
      notification {
        description
        level
        id
        unread
        timesince
        verb
        taskId
      }
    }
  }
`;
