import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import SuccessIcon from "@/assets/OfferSentSuccess.png";

const OfferSuccess = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          textAlign: "center",
          gap: 3,
        }}
      >
        <Box
          component="img"
          src={SuccessIcon}
          alt="Success"
          sx={{
            width: "120px",
            height: "120px",
            objectFit: "contain",
          }}
        />

        <Box sx={{ maxWidth: "300px" }}>
          <Typography
            variant="h5"
            component="h1"
            fontWeight="bold"
            gutterBottom
            sx={{ fontSize: { xs: "24px", md: "28px" } }}
          >
            {t("Offer Sent Successfully")}
          </Typography>

          <Typography
            color="text.secondary"
            sx={{ fontSize: { xs: "14px", md: "16px" } }}
          >
            {t(
              "Thank you for choosing this offer. We will review your choice and contact you soon for follow-up"
            )}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default OfferSuccess;
