import { Box, Pagination, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_REJECTED_TASKS } from "@/shared/graphql";

import { Loading } from "@/components/Loading";
import { RejectedBeneficiariesList } from "./BeneficiariesList/RejectedBenefeciariesList";
import { t } from "i18next";
import { CreateOrderModal } from "./CreateOrderModal";

const RejectedTasks = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);

  const pageLimit = 10;

  const {
    data,
    loading,
    refetch: refetchTasks,
  } = useQuery(GET_REJECTED_TASKS, {
    variables: {
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      orderBy: "-modified",
    },
  });

  const tasks = data?.rejectedTasks?.data;

  const numberOfPages = Math.ceil(data?.rejectedTasks?.count / pageLimit);
  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  if (loading) return <Loading />;
  return (
    <>
      {selectedTasks.length > 0 && (
        <Box sx={{ display: "flex", px: 2, alignItems: "center" }}>
          <Typography color="text.secondary" sx={{ mr: 2.4 }}>
            {t("selected")} {selectedTasks?.length}
          </Typography>
          <CreateOrderModal
            setSelectedBeneficiaries={
              setSelectedTasks as React.Dispatch<React.SetStateAction<never[]>>
            }
            selectedBeneficiaries={selectedTasks as never[]}
            refetchBeneficiaries={refetchTasks}
          />
        </Box>
      )}
      <RejectedBeneficiariesList
        tasks={tasks}
        selectedTasks={selectedTasks}
        setSelectedTasks={setSelectedTasks}
      />
      <Pagination
        sx={{ my: 2 }}
        count={numberOfPages || 1}
        page={Number(searchParams.get("page") || 1)}
        onChange={handlePagination}
        variant="outlined"
        shape="rounded"
      />
    </>
  );
};
export default RejectedTasks;
