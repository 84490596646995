import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EastIcon from "@mui/icons-material/East";
import PropertyFields from "./OfferDetails/PropertyFields";

const OfferDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedCard = location.state?.offer;
  const taskId = location.state?.taskId;

  if (!selectedCard) {
    return <Typography>No offer details available</Typography>;
  }

  const formatAddress = (address) => {
    if (!address) return "---";
    return `${address.street}, ${address.district}, ${address.city}`;
  };

  const handleChooseOffer = () => {
    navigate("../confirm-offer", {
      state: { offer: selectedCard, taskId },
      replace: true,
    });
  };

  const handleViewMap = () => {
    navigate("../offer-map", {
      state: {
        coordinates: selectedCard?.data?.location?.coordinate,
        offer: selectedCard,
        taskId,
      },
    });
  };

  return (
    <Container>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{
          mb: 3,
          fontSize: { xs: "18px", md: "24px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <NavLink to={"../"}>
          <EastIcon fontSize="medium" sx={{ mr: 1 }} />
        </NavLink>
        {t("Offer Details")}
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
            <Box sx={{ flex: 2 }}>
              <Box
                component="img"
                src={selectedCard?.offerData?.images[0]}
                alt="Main Image"
                sx={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Box>
            <Box
              sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}
            >
              {selectedCard?.offerData?.images
                .slice(1, 3)
                .map((image, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={image}
                    alt={`Secondary Image ${index + 1}`}
                    sx={{
                      width: "100%",
                      height: "195px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                ))}
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Typography variant="h4" fontWeight={"700"}>
            {selectedCard?.offerData.realEstatePrice} {t("SARs")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              flexDirection: "row",
            }}
          >
            <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
              {`${selectedCard.offerData?.establishmentType} |`}
            </Typography>
            {selectedCard.offerData?.offerRoom && (
              <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
                {`${selectedCard.offerData?.offerRoom}${t("rooms")} |`}
              </Typography>
            )}
            {selectedCard.offerData?.offerBathroom && (
              <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
                {`${selectedCard.offerData?.offerBathroom} ${t("bathrooms")} |`}
              </Typography>
            )}
            {selectedCard.offerData?.offerArea && (
              <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
                {`${selectedCard.offerData?.offerArea} ${t("sqmeters")}`}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FmdGoodOutlinedIcon />
            <Typography>
              {formatAddress(selectedCard?.offerData?.address)}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 1,
              py: 1.2,
              display: "flex",
              alignItems: "center",
              gap: 1,
              borderRadius: "8px",
            }}
            onClick={handleViewMap}
          >
            <FmdGoodOutlinedIcon />
            {t("View on Map")}
          </Button>

          <Typography variant="body2" fontWeight={"400"} sx={{ p: 2 }}>
            {selectedCard?.data?.offerData?.description}
          </Typography>

          <PropertyFields selectedOffer={selectedCard} />

          <Box sx={{ my: 3 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ py: 1.2 }}
              onClick={handleChooseOffer}
            >
              {t("Start Negotiation")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OfferDetails;
