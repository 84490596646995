import { gql } from "@apollo/client";

export const GET_ALL_FAVOURITE_OFFERS = gql`
  query favoriteOffers($taskId: Int!) {
    favoriteOffers(taskId: $taskId) {
      count
      isLinkExpired
      shareOffers
      data {
        reserved
        hasDeal
        id
        offerId
        taskId
        offer {
          status
          reservationRemainingHours
          offerData {
            establishmentType
            address
            realEstatePrice
            offerType
          }
          negotiators {
            count
            data {
              firstName
              lastName
            }
          }
          id
          geometry
          favoriteOfferId
          data
        }
      }
    }
  }
`;

export const GET_ALL_OFFERS = gql`
  query getAllOffers($offset: BoundedInt, $limit: BoundedInt, $taskId: Int!) {
    offers(pageInfo: { limit: $limit, offset: $offset }, taskId: $taskId) {
      boundaries
      geometries
      count
      data {
        data
        favoriteOfferId
        geometry
        id
        negotiators {
          count
          data {
            firstName
            lastName
          }
        }
        offerData {
          address
          establishmentType
          offerType
          realEstatePrice
        }
        reservationRemainingHours
        status
      }
    }
  }
`;
export const GET_OFFER_DATA = gql`
  query getOfferData($pk: Int) {
    offers(pk: $pk) {
      count
      data {
        data
      }
    }
  }
`;

export const GET_COORDINATES_DATA = gql`
  query offerLocationData($longitude: Float!, $latitude: Float!) {
    offerLocationData(longitude: $longitude, latitude: $latitude) {
      data
    }
  }
`;
