import { gql } from "@apollo/client";

export const CREATE_EXTERNAL_OFFER = gql`
  mutation CreateExternalOffer($offerInput: ExternalFavoriteOfferInputType!) {
    createExternalOfferMutation(offerInput: $offerInput) {
      favoriteOffer {
        id
      }
    }
  }
`;

export const CREATE_FAVORITE_OFFER = gql`
  mutation CreateFavoriteOffer($offerInput: CreateFavoriteOfferInputType!) {
    createFavoriteOfferMutation(offerInput: $offerInput) {
      favoriteOffer {
        id
      }
    }
  }
`;

export const CREATE_INTERNAL_OFFER = gql`
  mutation CreateOffer($offerInput: CreateOfferInputType!) {
    createOfferMutation(offerInput: $offerInput) {
      offer {
        id
      }
    }
  }
`;

export const ACTIVATE_REQUEST = gql`
  mutation ActivateRequest($taskId: Int!) {
    activateRequest(taskId: $taskId) {
      request {
        created
        data
        expired
        id
        modified
        status
      }
    }
  }
`;

export const UPDATE_INTERNAL_OFFER = gql`
  mutation UpdateOffer($offerInput: UpdateOfferInputType!) {
    updateOfferMutation(offerInput: $offerInput) {
      offer {
        id
      }
    }
  }
`;

export const DELETE_OFFER_FROM_FAVOURITES = gql`
  mutation DeleteOffer($favoriteOfferId: Int!) {
    deleteFavoriteOfferMutation(favoriteOfferId: $favoriteOfferId) {
      status
    }
  }
`;

export const SHARE_OFFERS_WITH_BENEFICIARY = gql`
  mutation ShareOffer($taskId: Int!) {
    shareOffersWithBeneficiary(taskId: $taskId) {
      request {
        created
      }
    }
  }
`;
