import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import EmptyOffer from "@/assets/emptyOffer.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { DELETE_OFFER_FROM_FAVOURITES } from "@/shared/graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import ErrorIcon from "@mui/icons-material/Error";
import toast from "react-hot-toast";
import { graphqlErrorHandler } from "@/shared/utils";
import { GET_BENEFECIARY_OFFERS } from "@/shared/graphql/queries/externalBenefeciary";
import { REQUEST_NEW_OFFERS } from "@/shared/graphql/mutations/beneficiary";
import { Loading } from "@/components/Loading";
import { NavLink, useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";

export const RequestNewOffers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data,
    refetch: refetchBenOffers,
    loading,
  } = useQuery(GET_BENEFECIARY_OFFERS);
  const beneficiaryOffers = data?.beneficiaryOffers?.offers;
  const taskId = data?.beneficiaryOffers?.taskId;

  const [deleteOffer, { loading: mutationLoading }] = useMutation(
    DELETE_OFFER_FROM_FAVOURITES
  );

  const [requestOffers, { loading: LoadingOffer }] =
    useMutation(REQUEST_NEW_OFFERS);
  const onRequest = () => {
    requestOffers({
      variables: {
        requestInput: {
          taskId,
        },
      },
    })
      .then(() => {
        toast.success(t("Requuest Sent Successfully"));
        refetchBenOffers();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  const onDelete = (favoriteOfferId) => {
    deleteOffer({
      variables: {
        favoriteOfferId: favoriteOfferId,
      },
    })
      .then(() => {
        toast.success(t("Offer Deleted Successfully"));
        refetchBenOffers();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  if (loading) {
    return <Loading />;
  }
  const handleOpenDetails = (offer) => {
    navigate("../offer-details", {
      state: { offer, taskId },
      replace: true,
    });
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              mb: 1,
              fontSize: { xs: "18px", md: "24px" },
              display: "flex",
            }}
          >
            <NavLink to={"../"}>
              <EastIcon fontSize="medium" sx={{ mr: 1 }} />
            </NavLink>
            {t("Request New Offers")}
          </Typography>

          <Typography
            color="error"
            sx={{
              mb: 1,
              fontSize: { xs: "15px", md: "17px" },
              display: "flex",
              gap: 1,
            }}
          >
            <ErrorIcon color="error" />
            {t(
              "To request more offers, you need to delete at least one offer."
            )}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={6} md={10} gap={3}>
          {beneficiaryOffers.map((offer, index) => (
            <Grid container item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  borderRadius: "16px",
                  overflow: "hidden",
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  py: 2,
                  px: 1,
                }}
                elevation={0}
              >
                <Grid
                  item
                  md={4}
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {offer?.offerData?.images?.length == 0 ? (
                    <Box
                      component="img"
                      src={EmptyOffer}
                      sx={{
                        borderRadius: "8px",
                        width: { xs: "125px", md: "100%" },
                        height: { xs: "110px", md: "100%" },
                      }}
                    />
                  ) : (
                    <Swiper
                      modules={[Navigation, Pagination]}
                      spaceBetween={10}
                      slidesPerView={1}
                      navigation={false}
                      loop={true}
                      pagination={{ clickable: true }}
                      className="custom-swiper"
                    >
                      {offer?.offerData?.images?.map((image, index) => (
                        <SwiperSlide key={index}>
                          <Box
                            component="img"
                            src={image}
                            alt={`Slide ${index + 1}`}
                            sx={{
                              borderRadius: "8px",
                              width: { xs: "125px", md: "100%" },
                              height: { xs: "110px", md: "100%" },
                            }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </Grid>

                <Grid item md={8} xs={8}>
                  <CardContent
                    sx={{ textAlign: "start", p: 2, cursor: "pointer" }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                      {offer?.offerData?.realEstatePrice} {t("SARs")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        flexDirection: "row",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: { md: 16, xs: 12 } }}
                        color="textSecondary"
                      >
                        {`${offer?.offerData?.establishmentType} |`}
                      </Typography>
                      {offer?.offerData?.offerRoom && (
                        <Typography
                          sx={{ fontSize: { md: 16, xs: 12 } }}
                          color="textSecondary"
                        >
                          {`${offer?.offerData?.offerRoom}${t("rooms")} |`}
                        </Typography>
                      )}
                      {offer?.offerData?.offerBathroom && (
                        <Typography
                          sx={{ fontSize: { md: 16, xs: 12 } }}
                          color="textSecondary"
                        >
                          {`${offer?.offerData?.offerBathroom} ${t(
                            "bathrooms"
                          )} |`}
                        </Typography>
                      )}
                      {offer?.offerData?.offerArea && (
                        <Typography
                          sx={{ fontSize: { md: 16, xs: 12 } }}
                          color="textSecondary"
                        >
                          {`${offer?.offerData?.offerArea} ${t("sqmeters")}`}
                        </Typography>
                      )}
                    </Box>
                    {offer.offerData.address && (
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "14px" },
                          display: "flex",
                          alignItems: "center",
                          mt: 1,
                        }}
                        color="textSecondary"
                      >
                        <FmdGoodOutlinedIcon
                          fontSize="small"
                          sx={{
                            mr: 0.5,
                            fontSize: { xs: "16px", md: "20px" },
                          }}
                        />
                        {`${offer.offerData.address.street}, ${offer.offerData.address.district}, ${offer.offerData.address.city}`}
                      </Typography>
                    )}
                  </CardContent>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "space-between" },
                    width: "100%",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => onDelete(offer.favoriteOfferId)}
                    color="error"
                    fullWidth
                    disabled={mutationLoading}
                    sx={{
                      fontSize: { xs: "10px", md: "14px" },
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      bgcolor: "rgba(255, 218, 215, 1)",
                    }}
                  >
                    {t("Cancel Offer")}
                  </Button>
                  <Button
                    variant="outlined"
                    fullWidth
                    color="primary"
                    disabled={mutationLoading}
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleOpenDetails(offer)}
                  >
                    {t("View Details")}
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "space-between" },
                mt: 4,
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={beneficiaryOffers.length === 2 || LoadingOffer}
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  textTransform: "capitalize",
                  width: "100%",
                }}
                onClick={onRequest}
              >
                {t("Request New Offers")}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  py: 1,
                  textTransform: "capitalize",
                  width: "100%",
                }}
                onClick={() => navigate("../")}
              >
                {t("Cancel")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
