import { AppCollapse } from "@/components/AppCollapse";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const BeneficiaryProfile = ({ beneficiaryData }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 2 }}>
      <AppCollapse title={t("Personal Data")}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography>{t("Beneficiary Name")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.personalData.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography>{t("Beneficiary Age")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.personalData.ageHijri}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("Mobile Number")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.personalData.mobileNumber}
          </Typography>
        </Box>
      </AppCollapse>
      <AppCollapse title={t("Personal Funding Data")}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("salary")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.financialData.salary}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("ٍSalary Deduction")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.financialData.monthlyDeduction}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("remaining Duration Months")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.financialData.remainingDurationMonths}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("salaryBank")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.financialData.salaryBank}
          </Typography>
        </Box>
      </AppCollapse>
      <AppCollapse title={t("Financial Preferences")}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("Purchase mechanism")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.financialPreferences.purchaseMechanism}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("Desire to purchase")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.financialPreferences.purchaseDesire}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("Preferred purchase amount")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.financialPreferences.preferredPurchaseAmount}
          </Typography>
        </Box>
      </AppCollapse>
      <AppCollapse title={t("Property Preferences")}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("Property Preffered type")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.realEstatePreferences.preferredPropertyType}
          </Typography>
        </Box>
      </AppCollapse>
      <AppCollapse title={t("Spatial preferences")}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography> {t("Preferred District")}</Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {beneficiaryData.locationPreferences.preferredCity}
          </Typography>
        </Box>
      </AppCollapse>
    </Box>
  );
};
export default BeneficiaryProfile;
