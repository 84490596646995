import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CHOOSE_OFFER } from "@/shared/graphql/mutations/beneficiary";
import { graphqlErrorHandler } from "@/shared/utils/graphqlErrorHandler";

const ConfirmOffer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedCard = location.state?.offer;
  const taskId = location.state?.taskId;

  const [chooseOffer, { loading: chooseOfferLoading }] =
    useMutation(CHOOSE_OFFER);

  const onChooseOffer = () => {
    chooseOffer({
      variables: {
        favoriteOfferInput: {
          taskId,
          favoriteOfferId: selectedCard?.favoriteOfferId,
        },
      },
    })
      .then(() => {
        navigate("../offer-success", { replace: true });
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const formatAddress = (address) => {
    if (!address) return "---";
    return `${address.street}, ${address.district}, ${address.city}`;
  };

  if (!selectedCard) {
    return <Typography>No offer details available</Typography>;
  }

  return (
    <Container maxWidth="sm" sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          gap: 3,
          pb: 4,
          pt: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          {t("Confirm Offer Choice")}
        </Typography>

        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 2,
            px: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                component="img"
                src={selectedCard?.offerData?.images[0]}
                alt="Property"
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  mb: 2,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="700" gutterBottom>
                {selectedCard?.offerData.realEstatePrice} {t("SARs")}
              </Typography>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1 }}>
                <Typography sx={{ fontSize: { md: 16, xs: 14 } }}>
                  {`${selectedCard.offerData?.establishmentType} |`}
                </Typography>
                {selectedCard.offerData?.offerRoom && (
                  <Typography sx={{ fontSize: { md: 16, xs: 14 } }}>
                    {`${selectedCard.offerData?.offerRoom}${t("rooms")} |`}
                  </Typography>
                )}
                {selectedCard.offerData?.offerBathroom && (
                  <Typography sx={{ fontSize: { md: 16, xs: 14 } }}>
                    {`${selectedCard.offerData?.offerBathroom} ${t(
                      "bathrooms"
                    )} |`}
                  </Typography>
                )}
                {selectedCard.offerData?.offerArea && (
                  <Typography sx={{ fontSize: { md: 16, xs: 14 } }}>
                    {`${selectedCard.offerData?.offerArea} ${t("sqmeters")}`}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}
              >
                <FmdGoodOutlinedIcon />
                <Typography fontSize="14px">
                  {formatAddress(selectedCard?.offerData?.address)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => navigate("../", { replace: true })}
            sx={{ py: 1.5 }}
          >
            {t("Back to Offers")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onChooseOffer}
            disabled={chooseOfferLoading}
            sx={{ py: 1.5 }}
          >
            {t("Confirm Choice")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ConfirmOffer;
