import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import React from "react";
import { useTranslation } from "react-i18next";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import EmptyOffer from "@/assets/emptyOffer.png";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_ACTIVATION_NOTIFICATION } from "@/shared/graphql/mutations/beneficiary";
import { graphqlErrorHandler } from "@/shared/utils/graphqlErrorHandler";
import toast from "react-hot-toast";

const BeneficiaryOffers = ({ beneficiaryOffers, taskId, isLinkExpired }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createActivation, { loading: activationLoading }] = useMutation(
    CREATE_ACTIVATION_NOTIFICATION
  );

  const handleCardClick = (offer) => {
    if (isLinkExpired) return;
    navigate("offer-details", { state: { offer, taskId } });
  };

  const formatAddress = (address) => {
    return `${address.street}, ${address.district} , ${address.city} `;
  };

  const handleRequestNewOffers = () => {
    navigate("/app/beneficiary/request-new-offers", {
      state: { offers: beneficiaryOffers },
      replace: true,
    });
  };

  const handleRequestActivation = () => {
    createActivation({
      variables: {
        taskId: Number(taskId),
      },
    })
      .then(() => {
        toast.success(t("Activation request sent successfully"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isLinkExpired && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(255, 255, 255, 0.7)",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <ErrorOutlinedIcon color="error" />
            <Typography color="error" variant="body1">
              {t("Link has expired")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRequestActivation}
              disabled={activationLoading}
              sx={{
                px: 2,
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              {t("Request New Activation")}
            </Button>
          </Box>
        </Box>
      )}

      <Grid container spacing={2} gap={2}>
        <Grid container item xs={12} sm={6} md={6} gap={3}>
          {beneficiaryOffers?.map((offer, index) => (
            <Grid container item xs={12} sm={12} md={12} key={index}>
              <Card
                sx={{
                  borderRadius: "16px",
                  overflow: "hidden",
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  py: 2,
                  px: 1,
                  filter: isLinkExpired ? "blur(4px)" : "none",
                  pointerEvents: isLinkExpired ? "none" : "auto",
                }}
                elevation={0}
              >
                <Grid
                  item
                  md={4}
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {offer?.offerData?.images?.length == 0 ? (
                    <Box
                      component="img"
                      src={EmptyOffer}
                      sx={{
                        borderRadius: "8px",
                        width: { xs: "125px", md: "100%" },
                        height: { xs: "110px", md: "100%" },
                      }}
                    />
                  ) : (
                    <Swiper
                      modules={[Navigation, Pagination]}
                      spaceBetween={10}
                      slidesPerView={1}
                      navigation={false}
                      loop={true}
                      pagination={{ clickable: true }}
                      className="custom-swiper"
                    >
                      {offer?.offerData?.images?.map((image, index) => (
                        <SwiperSlide key={index}>
                          <Box
                            component="img"
                            src={image}
                            alt={`Slide ${index + 1}`}
                            sx={{
                              borderRadius: "8px",
                              width: { xs: "125px", md: "100%" },
                              height: { xs: "110px", md: "100%" },
                            }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </Grid>
                <Grid item md={6} xs={8}>
                  <CardContent
                    sx={{ textAlign: "start", p: 2, cursor: "pointer" }}
                    onClick={() => handleCardClick(offer)}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                      {offer?.offerData?.realEstatePrice} {t("SARs")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        flexDirection: "row",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: { md: 16, xs: 12 } }}
                        color="textSecondary"
                      >
                        {`${offer?.offerData?.establishmentType} |`}
                      </Typography>
                      {offer?.offerData?.offerRoom && (
                        <Typography
                          sx={{ fontSize: { md: 16, xs: 12 } }}
                          color="textSecondary"
                        >
                          {`${offer?.offerData?.offerRoom}${t("rooms")} |`}
                        </Typography>
                      )}
                      {offer?.offerData?.offerBathroom && (
                        <Typography
                          sx={{ fontSize: { md: 16, xs: 12 } }}
                          color="textSecondary"
                        >
                          {`${offer?.offerData?.offerBathroom} ${t(
                            "bathrooms"
                          )} |`}
                        </Typography>
                      )}
                      {offer?.offerData?.offerArea && (
                        <Typography
                          sx={{ fontSize: { md: 16, xs: 12 } }}
                          color="textSecondary"
                        >
                          {`${offer?.offerData?.offerArea} ${t("sqmeters")}`}
                        </Typography>
                      )}
                    </Box>

                    {offer.offerData.address && (
                      <Typography
                        sx={{
                          fontSize: { md: 16, xs: 12 },
                          display: "flex",
                          mt: 1,
                        }}
                        color="textSecondary"
                      >
                        <FmdGoodOutlinedIcon fontSize="small" />{" "}
                        {formatAddress(offer.offerData.address) ?? "---"}
                      </Typography>
                    )}
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Grid container item xs={12} sm={6} md={5.5} height={"100%"}>
          <Card
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              width: { md: "80%", xs: "100%" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", md: "flex-start" },
              alignItems: "flex-start",
              p: 2,
            }}
            elevation={0}
          >
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              {t("New Offers")}
            </Typography>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              {t("Don't see what you like?")}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mb: 2, textAlign: "justify" }}
            >
              {t(
                "Let us know your preferences and we'll help you find your dream property!"
              )}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRequestNewOffers}
              sx={{ mt: 2, py: 1 }}
            >
              {t("Request New Offers")}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BeneficiaryOffers;
