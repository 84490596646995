import { useEffect, useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import OSM from "ol/source/OSM";
import { defaults as defaultControls } from "ol/control";
import { fromLonLat } from "ol/proj";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Icon from "ol/style/Icon";
import EastIcon from "@mui/icons-material/East";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const OSMLayer = new TileLayer({ visible: true, source: new OSM() });
OSMLayer.set("name", "OpenStreetMap");

const OfferMap = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const mapRef = useRef<Map>();
  const domRef = useRef<HTMLDivElement | null>(null);

  const coordinates = location.state?.coordinates;

  useEffect(() => {
    if (!coordinates) {
      navigate("../");
      return;
    }

    if (mapRef.current) return;

    const vectorSource = new VectorSource({});
    const point = new Point(fromLonLat(coordinates?.coordinates));
    const feature = new Feature({
      geometry: point,
    });
    const markerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src:
          "data:image/svg+xml;utf8," +
          encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
            <path fill="#000" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"/>
          </svg>
        `),
        scale: 1.5,
      }),
    });

    feature.setStyle(markerStyle);
    vectorSource.addFeature(feature);

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const mapView = new View({
      center: fromLonLat(coordinates?.coordinates),
      zoom: 12,
    });

    mapRef.current = new Map({
      layers: [OSMLayer, vectorLayer],
      view: mapView,
      target: domRef?.current || undefined,
      controls: defaultControls({
        zoom: false,
        attribution: false,
        rotate: false,
      }),
    });
  }, [coordinates, navigate]);

  const handleClose = () => {
    navigate("../offer-details", { state: location.state });
  };

  if (!coordinates) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        bgcolor: "background.paper",
        p: 0,
        mt: 0,
      }}
    >
      <Box
        sx={{
          p: 2,
          zIndex: 1,
          bgcolor: "background.paper",
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            bgcolor: "background.paper",
            "&:hover": {
              bgcolor: "background.paper",
            },
          }}
        >
          <EastIcon fontSize="medium" sx={{ mr: 1 }} />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">
          {t("Property Location")}
        </Typography>
      </Box>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={domRef}
      />
    </Box>
  );
};

export default OfferMap;
