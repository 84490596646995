import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const formatTimestamp = (timestamp: string) => {
  return new Date(timestamp)?.toLocaleString("ar-EG", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

interface NotificationI {
  id: string;
  verb: string;
  description: string;
  timesince: string;
  unread: boolean;
  taskId: string;
  level: "warning" | "info" | "success";
}

interface NotificationItemProps {
  notificationData: NotificationI;
  handleDeleteNotification: (pk: string) => void;
  markReadNotification: (pk: string) => void;
  handleNotificationsClose: () => void;
}

const getIconColor = (level: string) => {
  switch (level) {
    case "WARNING":
      return {
        bg: "rgba(210, 226, 245, 1)",
        color: "rgba(122, 96, 255, 1)",
      };
    case "INFO":
      return {
        bg: "rgba(3, 169, 244, 0.1)",
        color: "rgba(38, 119, 240, 1)",
      };
    case "SUCCESS":
      return {
        bg: "rgba(208, 249, 231, 1)",
        color: "rgba(32, 191, 127, 1)",
      };
    default:
      return {
        bg: "rgba(208, 249, 231, 1)",
        color: "rgba(32, 191, 127, 1)",
      };
  }
};

const getNotificationIcon = (level: string) => {
  const colors = getIconColor(level);
  switch (level) {
    case "WARNING":
      return <AddIcon sx={{ color: colors.color, fontSize: 18 }} />;
    case "INFO":
      return <CachedIcon sx={{ color: colors.color, fontSize: 18 }} />;
    case "SUCCESS":
      return <CheckIcon sx={{ color: colors.color, fontSize: 18 }} />;
    default:
      return <CheckIcon sx={{ color: colors.color, fontSize: 18 }} />;
  }
};

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notificationData,
  handleDeleteNotification,
  markReadNotification,
  handleNotificationsClose,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const iconColors = getIconColor(notificationData.level);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = () => {
    if (notificationData.taskId) {
      handleNotificationsClose();
      navigate(`../negotiator/tasks/${notificationData.taskId}`);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderBottom: "1px solid",
        borderColor: "divider",
        "&:last-child": {
          borderBottom: "none",
        },
        position: "relative",
        cursor: "pointer",
      }}
      onClick={handleNavigate}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          flex: 1,
          pr: notificationData.unread ? 4 : 0,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
          {notificationData.unread && (
            <Box
              sx={{
                width: 8,
                height: 8,
                mt: 1.5,
                borderRadius: "50%",
                bgcolor: "rgba(50, 138, 241, 1)",
              }}
            />
          )}
          <Box
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              bgcolor: iconColors.bg,
              width: 32,
              height: 32,
              minWidth: 32,
            }}
          >
            {getNotificationIcon(notificationData.level)}
          </Box>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography variant="body1" sx={{ mb: 0.5 }}>
            {notificationData.description}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {formatTimestamp(notificationData.timesince)}
          </Typography>
        </Box>
      </Box>

      <IconButton size="small" onClick={handleClick} sx={{ mt: -0.5 }}>
        <MoreVert fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem
          onClick={() => {
            if (notificationData.unread) {
              markReadNotification(notificationData.id);
            }
            handleClose();
          }}
          sx={{ gap: 1 }}
        >
          <CheckCircleOutlineOutlinedIcon sx={{ color: "" }} />
          <Typography variant="body2" fontWeight={"bold"}>
            {t("Mark as read")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteNotification(notificationData.id);
            handleClose();
          }}
          sx={{ gap: 1 }}
        >
          <DeleteOutlineOutlinedIcon sx={{ color: "error.main" }} />
          <Typography variant="body2" sx={{ color: "error.main" }}>
            {t("Delete")}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
